/*------------------------------------*\
    Variables
\*------------------------------------*/

:root {
  --gray-color: hsl(0, 0%, 25%);
  --coral-color: hsl(0, 97%, 66%);
  --indigo-color: hsl(250, 100%, 69%);
  --indigo-light-color: hsl(266, 100%, 64%);
  --turqois-color: hsl(183, 55%, 61%);
}
