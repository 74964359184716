@charset "utf-8";

/*------------------------------------*\
    Application Stylesheet
\*------------------------------------*/

/**
 * Base
 * ----------------------------------------------------------------------------
 * The base/ folder holds what we might call the boilerplate code for the project.
 * In there, you might find the reset file, some typographic rules, and probably a
 * stylesheet defining some standard styles for commonly used HTML elements
 */

@import "base/reset.css";
@import "base/variables.css";
@import "base/typography.css";

/**
 * Layout
 * ----------------------------------------------------------------------------
 * The layout/ folder contains everything that takes part in laying out the site
 * or application. This folder could have stylesheets for the main parts of the
 * site (header, footer, navigation, sidebar…), the grid system or even CSS styles
 * for all the forms.
 */

@import "layout/footer.css";
@import "layout/main.css";

/**
 * Components
 * ----------------------------------------------------------------------------
 * For smaller components, there is the components/ folder. While layout/ is macro
 * (defining the global wireframe), components/ is more focused on widgets.
 * It contains all kind of specific modules like a slider, a loader, a widget,
 * and basically anything along those lines. There are usually a lot of files
 * in components/ since the whole site/application should be mostly composed of
 * tiny modules.
 */

@import "components/button.css";
@import "components/hero.css";
@import "components/introduction.css";
@import "components/navigation.css";
@import "components/project.css";
@import "components/tagline.css";
