/*------------------------------------*\
    Navigation
\*------------------------------------*/

nav.main-nav {
  display: none;
  position: sticky;
  z-index: 999;
  top: 0;

  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2em;
  margin: 0 -1em;
  padding: 1em;

  background-color: hsla(0, 0%, 100%, 0.85);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

nav.main-nav ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

nav.main-nav ul li {
  display: inline-block;
}

nav.main-nav ul li a {
  display: block;
  padding: 0.5em 0.25em;
  margin-right: 0.25em;
  font-size: 1rem;
  font-weight: 700;
  color: var(--gray-color);
  text-decoration: none;
  transition: color 175ms ease-in-out;
}

nav.main-nav ul li a[href="#about-me"]:hover {
  color: var(--indigo-light-color);
}

nav.main-nav ul li a[href="#projects"]:hover {
  color: var(--coral-color);
}

nav.main-nav ul li a[href="#contact"]:hover {
  color: var(--turqois-color);
}

@media (min-width: 768px) {
  nav.main-nav {
    display: flex;
    margin-top: 6em;
  }

  nav.main-nav ul li a {
    font-size: 1.5rem;
  }
}
