/*------------------------------------*\
    Hero
\*------------------------------------*/

section.hero {
  margin-top: 1em;
}

section.hero p {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
}

section.hero p:last-child {
  text-align: right;
}

div.hero__name {
  position: relative;
  overflow: hidden;
  line-height: 0;
}

div.hero__name svg {
  display: block;
  max-width: 100%;
}

div.hero__name svg.logo {
  position: relative;
  z-index: 5;
  height: 100%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}

div.hero__name svg.blob-coral,
div.hero__name svg.blob-turqoise,
div.hero__name svg.blob-dustpink,
div.hero__name svg.blob-indigo {
  position: absolute;
  top: -75%;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: rotate;
}

div.hero__name svg.blob-coral {
  left: -35%;
}

div.hero__name svg.blob-dustpink {
  left: 0;
}

div.hero__name svg.blob-indigo {
  left: 35%;
}

div.hero__name svg.blob-turqoise {
  left: 70%;
}

@media (min-width: 768px) {
  section.hero {
    margin-top: 6em;
  }

  section.hero p {
    font-size: 2.25rem;
  }

  div.hero__name svg.blob-coral,
  div.hero__name svg.blob-turqoise,
  div.hero__name svg.blob-dustpink,
  div.hero__name svg.blob-indigo {
    top: -50%;
  }
}
