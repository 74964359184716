/*------------------------------------*\
    Main
\*------------------------------------*/

main {
  width: min(1200px, 100vw - 2rem);
  margin: 0 auto;
  margin-bottom: 4rem;
}

body {
  color: var(--gray-color);
}
