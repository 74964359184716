/*------------------------------------*\
    Introduction
\*------------------------------------*/

section.introduction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2em;
  margin: 6em 0;
}

section.introduction article {
  order: 2;
}

section.introduction article h1 {
  font-size: 2rem;
}

section.introduction article p {
  font-size: 1rem;
  margin-bottom: 1em;
}

.introduction-image {
  position: relative;
  z-index: 1;
  background-image: url('/images/profile-highlight.svg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 34px 37px;
}

.introduction-image::before,
.introduction-image::after {
  position: absolute;
  content: "";
  border-radius: 9999px;
  transition: all 275ms ease-in-out;
}

.introduction-image::before {
  top: 65%;
  left: 0;
  width: 15vw;
  height: 15vw;
  max-width: 12em;
  max-height: 12em;
  background: linear-gradient(222.68deg, #FFE0E0 14.07%, rgba(252, 83, 83, 0.9) 86.15%);
}

.introduction-image::after {
  top: 90%;
  right: 20%;
  width: 7vw;
  height: 7vw;
  background: linear-gradient(222.68deg, #DB84BD 14.07%, #9747FF 86.15%);
}

.introduction-image img {
  max-width: 15em;
  border-radius: 9999px;
}

@media (min-width: 768px) {
  section.introduction {
    flex-direction: row;
  }

  section.introduction article {
    order: 0;
    padding-right: 6em;
  }

  section.introduction article p {
    font-size: 1.25rem;
  }

  .introduction-image img {
    max-width: 20em;
  }

  .introduction-image::before {
    left: -10%;
  }

  .introduction-image::after {
    right: 10%;
    top: 95%;
  }
}
