/*------------------------------------*\
    Button
\*------------------------------------*/

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: .75em 1.75em;
  margin: 0;

  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  color: var(--gray-color);

  user-select: none;
  transition: color 175ms ease-in-out;

  background: none;
  border: 1px solid transparent;
  border-radius: 9999px;

  overflow-wrap: break-word;
  word-break: break-all;
  hyphens: auto;

  outline: none;
}

.btn:focus { outline: none; }
a.btn { text-decoration: none; }

.btn.btn-solid {
  background-color: var(--gray-color);
  border-color: var(--gray-color);
  color: #ffffff;
}

.btn.btn-solid:hover {
  background: linear-gradient(270deg, var(--coral-color) 0%, var(--indigo-light-color) 100%);
  border-color: transparent;
}

.btn.btn-ghost {
  border: 1px solid var(--gray-color);
  color: var(--gray-color);
}

.btn.btn-ghost:hover {
  border-color: var(--coral-color);
  color: var(--coral-color);
}

@media (min-width: 768px) {
  .btn {
    font-size: 1.25rem;
  }
}
