/*------------------------------------*\
    Tagline
\*------------------------------------*/

section.tagline {
  margin: 3em 0;
}

section.tagline h1 {
  font-size: 1.5rem;
  font-weight: 700;
}

section.tagline h1 + h1 {
  display: none;
}

section.tagline h1 + a {
  margin-top: 1em;
}

section.tagline span {
  position: relative;
  display: inline-block;
}

section.tagline span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 35px;
  background-color: transparent;
  background-image: url("/images/underlining.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  section.tagline {
    margin: 4em 0;
  }

  section.tagline h1 {
    font-size: 2rem;
  }

  section.tagline h1 + h1 {
    display: block;
  }

  section.tagline h1 + a {
    margin-top: 2em;
  }

  section.tagline span::after {
    top: 75%;
  }
}

@media (min-width: 992px) {
  section.tagline h1 {
    font-size: 3rem;
  }
}
