/*------------------------------------*\
    Footer
\*------------------------------------*/

footer.main-footer {
  margin-top: 6em;
}

footer.main-footer h2 {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  font-size: 1.75rem;
}

footer.main-footer h2 svg {
  width: 2em;
  height: 2em;
}

footer.main-footer p {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

footer.main-footer a {
  margin-top: 1em;
}

@media (min-width: 768px) {
  footer.main-footer {
    margin-top: 10em;
    padding-right: 45%;
  }
}
