/*------------------------------------*\
    Project
\*------------------------------------*/

section.projects {
  margin-top: 6em;
}

section.projects ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;

  list-style: none;
  margin: 0;
  padding: 0;
}

section.projects ul li {
  width: 100%;
}

section.projects ul li:not(.project),
section.projects ul li.project a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem 2rem;
}

section.projects ul li.project a {
  position: relative;
  z-index: 5;
  height: 100%;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid transparent;
  transition: all 375ms ease-in-out;
}

section.projects ul li.project a:hover {
  z-index: 1;
  transform: scale(1.02);
  box-shadow: 0 15px 35px hsla(0, 0%, 25%, 0.07);
}

section.projects ul li.project--me-mail a {
  background-color: #FFE0E0;
}

section.projects ul li.project--pawesome a {
  background-color: #ffffff;
  border-color: #EF823F;
}

section.projects ul li.project--resume a {
  background-color: #FFF5E9;
}

@media (min-width: 768px) {
  section.projects {
    margin-top: 10em;
  }
}
